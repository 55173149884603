<template>
  <div>
    <v-card-title
      v-if="$route['meta']['title'] === 'Admin Dashboard'"
      class="px-0 pb-0"
    >
      All Users
    </v-card-title>
    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          v-model="search"
          dense
          class="white mb-2 pa-2"
          style="height: 38px"
          placeholder="Search with name, email or status"
          @input="searchUser"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="filter"
          dense
          class="white pa-2"
          placeholder="filter users"
          style="height: 38px"
          :items="userTypes"
          @change="filterUsers(filter)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-row class="px-3" justify-md="end">
          <v-btn
            outlined
            class="primary--text rounded-xl capitalize mt-md-4"
            @click="exportContacts"
            :disabled="loading"
          >
            Export Contacts
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              size="20"
              width="2"
              class="ml-2"
            ></v-progress-circular>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="users"
          :headers="headers"
          :hide-default-footer="true"
          :loading="loadUsers"
          item-key="item.id"
          :items-per-page="10"
        >
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>{{ new Date(item["created_at"]).toDateString() }}</div>
          </template>
          <template v-slot:item.account_type="{ item }">
            <div class="capitalize">{{ item["account_type"] }}</div>
          </template>
          <template v-slot:item.status="{ item }">
            <div
              :class="
                item.status === 'suspended'
                  ? 'capitalize error--text'
                  : 'capitalize'
              "
            >
              {{ item.status }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    link
                    exact
                    v-for="(link, i) in dropdownLinks"
                    :key="i"
                    @click="
                      link['title'] === 'Profile'
                        ? viewDetails(item)
                        : deleteUser(item)
                    "
                  >
                    <v-list-item-content>{{ link.title }}</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      item.status === 'suspended' || item['deleted_at'] !== null
                    "
                    link
                    exact
                    @click="suspendOrActivateUser(item)"
                  >
                    <v-list-item-content>Activate</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-else
                    link
                    exact
                    @click="suspendOrActivateUser(item)"
                  >
                    <v-list-item-content>Suspend</v-list-item-content>
                  </v-list-item>
                  <v-list-item link exact @click="openDialog(item, 'dialog')">
                    <v-list-item-content>Upgrade/Downgrade</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    exact
                    @click="openDialog(item, 'addUserDialog')"
                  >
                    <v-list-item-content>
                      Add user to account
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
        <!--suppress ES6ModulesDependencies -->
        <div v-if="pagination.total > 1">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.total"
            :total-visible="7"
            @input="nextPage()"
          ></v-pagination>
        </div>
      </v-col>
      <v-dialog v-model="dialog" max-width="500">
        <v-card class="white" flat>
          <v-toolbar class="text-h5 primary white--text" flat>
            Upgrade/Downgrade &nbsp;<small> {{ currentUser.email }}</small>
          </v-toolbar>
          <v-form lazy-validation ref="form" class="pa-4">
            <v-autocomplete
              v-model="plan"
              :items="plans"
              dense
              class="white pa-2"
              placeholder="Select plan"
              style="height: 38px"
              item-text="name"
              item-value="id"
              :rules="[(v) => !!v || 'Please select a plan']"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.lifetime"
              :items="[
                { text: 'Yes', value: 'yes' },
                { text: 'No', value: 'no' },
              ]"
              dense
              class="white pa-2 mt-4"
              placeholder="Is this a lifetime access?"
              style="height: 38px"
              item-text="text"
              item-value="value"
              :rules="[(v) => !!v || 'Please select lifetime access']"
            ></v-autocomplete>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              class="error"
              @click="[(dialog = !dialog), (form = {})]"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              class="primary"
              @click="$refs.form['validate']() ? upgradeDowngradeUser() : null"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addUserDialog" max-width="450">
        <v-card class="white" flat>
          <v-toolbar class="text-h5 primary white--text" flat>
            <small>Add user to {{ currentUser.email }}'s account</small>
          </v-toolbar>
          <v-form lazy-validation ref="add_user" class="pa-4">
            <v-text-field
              v-model="account_email"
              dense
              class="white pa-2"
              placeholder="Enter email of user you want to add to this account"
              style="height: 38px"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              class="error"
              @click="addUserDialog = !addUserDialog"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              class="primary"
              @click="$refs.add_user['validate']() ? addUserToAccount() : null"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import exportFromJSON from "export-from-json";
export default {
  name: "Users",
  data() {
    return {
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Last Seen", value: "last_seen" },
        { text: "Plan", value: "account_type" },
        { text: "Partnered", value: "partnered" },
        { text: "Status", value: "status" },
        { text: "Date Added", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      users: [],
      loadUsers: false,
      loading: false,
      pagination: {
        total: 1,
        page: 1,
      },
      search: "",
      dropdownLinks: [
        { title: "Profile", link: "/users/profile" },
        { title: "Delete", link: "users/delete" },
      ],
      form: {},
      timerId: null,
      filter: "",
      userTypes: [
        { text: "All Users", value: "all_users" },
        { text: "AppSumo Users", value: "appsumo_users" },
        { text: "Partnered User", value: "partnered_users" },
        { text: "Subscribed Users", value: "subscribed_users" },
        { text: "Video Request Users", value: "video_request_users" },
        { text: "Free Users", value: "free_users" },
      ],
      dialog: false,
      currentUser: {},
      plan: "",
      plans: [],
      account_email: "",
      addUserDialog: false,
      users_for_export: [],
    };
  },
  created() {
    this.getUsers();
    this.getPlans();
  },
  methods: {
    ...mapActions({
      get_users: "users/get_users",
      delete_user: "users/delete_user",
      filter_users: "users/filter_users",
      suspend_user: "users/suspend_user",
      get_plans: "payments/get_plans",
      add_payment: "payments/add_payment",
      add_user_to_account: "users/add_user_to_account",
    }),
    ...mapMutations("auth", ["LOADING"]),
    searchUser() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(() => {
        let query = "?page=1&search=" + this.search + "&filter=" + this.filter;
        this.getUsers(query);
      }, 800);
    },
    async getUsers(query) {
      if (!query) {
        query = "?page=" + this.pagination.page;
      }
      this.loadUsers = true;
      try {
        const response = await this.get_users(query);
        let res = response.data;
        this.users = res.users.data;
        this.users_for_export = res.users_for_export;
        this.pagination.page = res.users["current_page"];
        this.pagination.total = res.users["last_page"];
        this.$parent.all_users = response.data["total_users_count"];
        this.$parent.partnered_users = response.data["total_partnered_users"];
        this.$parent.subscribed_users = response.data["total_subscribed_users"];
        this.$parent.free_users = response.data["total_free_users"];
        this.$parent.video_request_users = response.data["total_request_users"];
        this.loadUsers = false;
      } catch (e) {
        this.$response.sendError(e);
        this.loadUsers = false;
      }
    },
    nextPage() {
      let page = this.pagination.page;
      let search = this.search;
      let filter = this.filter;
      let query = "?page=" + page;
      if (search !== "") {
        query = "?page=" + page + "&search=" + search;
      }
      if (filter !== "") {
        query = "?page=" + page + "&search=" + search + "&filter=" + filter;
      }
      this.getUsers(query);
    },
    viewDetails(user) {
      this.$store.commit("users/USER", user);
      this.$router.push({ name: "UserProfile", params: { id: user.id } });
    },
    async deleteUser(user) {
      let confirmDelete = confirm("This action is not reversible...");
      if (confirmDelete) {
        this.form.user_id = user.id;
        this.LOADING(true);
        try {
          const response = await this.delete_user(this.form);
          this.LOADING(false);
          await this.searchUser();
          this.$root.$emit("reload-analysis");
          this.$response.sendSuccess(response);
        } catch (e) {
          this.LOADING(false);
          this.$response.sendError(e);
        }
      }
    },
    async filterUsers(filter) {
      this.LOADING(true);
      try {
        const query = "?page=1&filter=" + filter;
        const response = await this.filter_users(query);
        this.users = response.data.users.data;
        this.users_for_export = response.data.users_for_export;
        this.pagination.page = response.data.users["current_page"];
        this.pagination.total = response.data.users["last_page"];
        this.LOADING(false);
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    async suspendOrActivateUser(user) {
      this.LOADING(true);
      const form = {
        user_id: user.id,
      };
      try {
        const response = await this.suspend_user(form);
        this.LOADING(false);
        this.$response.sendSuccess(response);
        await this.searchUser();
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    openDialog(user, dialog) {
      if (dialog === "dialog") {
        this.dialog = true;
      } else if (dialog === "addUserDialog") {
        this.addUserDialog = true;
      }
      this.currentUser = Object.assign({}, user);
    },
    async getPlans() {
      try {
        const response = await this.get_plans();
        this.plans = response.data.sort((a, b) => a.amount - b.amount);
      } catch (e) {
        console.log(e);
      }
    },
    async upgradeDowngradeUser() {
      this.LOADING(true);
      const data = {
        admin: "admin",
        user_id: this.currentUser.id,
        email: this.currentUser.email,
        flw_ref: "admin-inv" + Math.floor(Math.random() * 100000000),
        transaction_id: "admin-txid" + Math.floor(Math.random() * 100000000),
        upgrade_id: this.plan,
        lifetime: this.form.lifetime,
      };
      try {
        const response = await this.add_payment(data);
        this.LOADING(false);
        this.dialog = false;
        this.$response.sendSuccess(response);
        this.plan = "";
        this.form.lifetime = "";
        await this.searchUser();
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    async addUserToAccount() {
      this.LOADING(true);
      const data = {
        admin: "admin",
        account_email: this.account_email,
        user_id: this.currentUser.id,
      };
      try {
        const response = await this.add_user_to_account(data);
        this.LOADING(false);
        this.$response.sendSuccess(response);
        this.addUserDialog = !this.addUserDialog;
        this.account_email = "";
        await this.searchUser();
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    async exportContacts() {
      this.loading = true;
      const data = this.users_for_export;
      const fileName = this.filter !== "" ? this.filter : "all_users";
      const exportType = exportFromJSON.types.csv;
      if (!data.length) {
        this.$response.staticError("Users list is empty");
        return;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Perform the actual export after the delay
        await exportFromJSON({ data, fileName, exportType });

        console.log("Export completed successfully");
      } catch (error) {
        console.error("Export failed:", error);
        this.$response.staticError("Export failed");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
